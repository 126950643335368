// Generated by Framer (d2b7042)

import { addFonts, addPropertyControls, ControlType, cx, CycleVariantState, Image, useActiveVariantCallback, useLocaleInfo, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion, MotionConfigContext } from "framer-motion";
import * as React from "react";

const cycleOrder = ["khaD8joO3", "zTBLJni3L", "gkK1mOqKf", "ZW1TDqz9I", "fSMUMbj8g", "b0F184wE7", "Ac_FnKne_", "z8sZWDg8O", "y5dF9DJox", "rBiO05SxQ"];

const serializationHash = "framer-xloUg"

const variantClassNames = {Ac_FnKne_: "framer-v-1bq6ug6", b0F184wE7: "framer-v-1nw8yhr", fSMUMbj8g: "framer-v-27m287", gkK1mOqKf: "framer-v-17b6pxd", khaD8joO3: "framer-v-1wz4yup", rBiO05SxQ: "framer-v-15blxg0", y5dF9DJox: "framer-v-vkpzqb", z8sZWDg8O: "framer-v-17q0j7m", zTBLJni3L: "framer-v-19jzw8y", ZW1TDqz9I: "framer-v-1h38w32"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const transitions = {default: {damping: 60, delay: 0, mass: 1, stiffness: 500, type: "spring"}, ZW1TDqz9I: {delay: 0, duration: 0.3, ease: [0.44, 0, 0.56, 1], type: "tween"}};

const toResponsiveImage = (value) => {
    if (typeof value === "object" && value !== null && typeof value.src === "string") {
        return value;
    };
    return typeof value === "string" ? {src: value} : undefined;
};


const Transition = ({value, children}) => {
const config = React.useContext(MotionConfigContext)
const transition = value ?? config.transition
const contextValue = React.useMemo(() => ({ ...config, transition }), [JSON.stringify(transition)])
return <MotionConfigContext.Provider value={contextValue}>{children}</MotionConfigContext.Provider>
}

const Variants = motion(React.Fragment)

const humanReadableVariantMap = {"Image 1:1": "b0F184wE7", "Image 16:9": "ZW1TDqz9I", "Image 3:2": "gkK1mOqKf", "Image 4:3": "zTBLJni3L", "Image 4:5": "fSMUMbj8g", "Variant 10": "rBiO05SxQ", "Variant 7": "Ac_FnKne_", "Variant 8": "z8sZWDg8O", "Variant 9": "y5dF9DJox", Default: "khaD8joO3"}

const getProps = ({height, id, image, mouseEnter, width, ...props}) => { return {...props, variant: humanReadableVariantMap[props.variant] ?? props.variant ?? "khaD8joO3", vhrLqezAm: mouseEnter ?? props.vhrLqezAm, WKR34BYYJ: image ?? props.WKR34BYYJ} }

const createLayoutDependency = (props, variants) => variants.join('-') + props.layoutDependency

export interface Props extends React.HTMLAttributes<HTMLDivElement> { style?: Record<string, unknown>;className?: string;layoutId?: string | number;variant?: keyof typeof humanReadableVariantMap;image?: {src: string; srcSet?: string};mouseEnter?: any; }

const Component = React.forwardRef<HTMLDivElement, Props>(function(props, ref) {

const { activeLocale, setLocale } = useLocaleInfo()

const {style, className, layoutId, variant, WKR34BYYJ, vhrLqezAm, ...restProps} = getProps(props)

const {baseVariant, classNames, gestureVariant, setGestureState, setVariant, transition, variants} = useVariantState({cycleOrder, defaultVariant: "khaD8joO3", transitions, variant, variantClassNames})

const layoutDependency = createLayoutDependency(props, variants)

const { activeVariantCallback, delay } = useActiveVariantCallback(baseVariant)

const onMouseEnterslyzpm = activeVariantCallback(async (...args) => {
if (vhrLqezAm) {
const res = await vhrLqezAm(...args);
if (res === false) return false;
}
})

const ref1 = React.useRef<HTMLElement>(null)

const defaultLayoutId = React.useId()

const sharedStyleClassNames = []

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<Variants animate={variants} initial={false}><Transition value={transition}><Image {...restProps} background={{alt: "", fit: "fill", sizes: "min(256px, 100vw)", ...toResponsiveImage(WKR34BYYJ)}} className={cx(serializationHash, ...sharedStyleClassNames, "framer-1wz4yup", className, classNames)} data-framer-name={"Default"} data-highlight layoutDependency={layoutDependency} layoutId={"khaD8joO3"} onHoverEnd={() => setGestureState({isHovered: false})} onHoverStart={() => setGestureState({isHovered: true})} onMouseEnter={onMouseEnterslyzpm} onTap={() => setGestureState({isPressed: false})} onTapCancel={() => setGestureState({isPressed: false})} onTapStart={() => setGestureState({isPressed: true})} ref={ref ?? ref1} style={{...style}} {...addPropertyOverrides({Ac_FnKne_: {"data-framer-name": "Variant 7"}, b0F184wE7: {"data-framer-name": "Image 1:1"}, fSMUMbj8g: {"data-framer-name": "Image 4:5"}, gkK1mOqKf: {"data-framer-name": "Image 3:2"}, rBiO05SxQ: {"data-framer-name": "Variant 10", background: {alt: "", fit: "fill", sizes: "min(352px, 100vw)", ...toResponsiveImage(WKR34BYYJ)}}, y5dF9DJox: {"data-framer-name": "Variant 9"}, z8sZWDg8O: {"data-framer-name": "Variant 8"}, zTBLJni3L: {"data-framer-name": "Image 4:3"}, ZW1TDqz9I: {"data-framer-name": "Image 16:9"}}, baseVariant, gestureVariant)}/></Transition></Variants>
</LayoutGroup>)

});

const css = [".framer-xloUg[data-border=\"true\"]::after, .framer-xloUg [data-border=\"true\"]::after { content: \"\"; border-width: var(--border-top-width, 0) var(--border-right-width, 0) var(--border-bottom-width, 0) var(--border-left-width, 0); border-color: var(--border-color, none); border-style: var(--border-style, none); width: 100%; height: 100%; position: absolute; box-sizing: border-box; left: 0; top: 0; border-radius: inherit; pointer-events: none; }", "@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-xloUg.framer-okuyq9, .framer-xloUg .framer-okuyq9 { display: block; }", ".framer-xloUg.framer-1wz4yup { height: 256px; overflow: hidden; position: relative; width: 256px; }", ".framer-xloUg.framer-v-19jzw8y.framer-1wz4yup { aspect-ratio: 1.3333333333333333 / 1; height: var(--framer-aspect-ratio-supported, 192px); }", ".framer-xloUg.framer-v-17b6pxd.framer-1wz4yup { aspect-ratio: 1.4970760233918128 / 1; height: var(--framer-aspect-ratio-supported, 171px); }", ".framer-xloUg.framer-v-1h38w32.framer-1wz4yup { aspect-ratio: 1.7777777777777777 / 1; height: var(--framer-aspect-ratio-supported, 144px); }", ".framer-xloUg.framer-v-27m287.framer-1wz4yup { aspect-ratio: 0.8 / 1; height: var(--framer-aspect-ratio-supported, 320px); }", ".framer-xloUg.framer-v-1nw8yhr.framer-1wz4yup { aspect-ratio: 1 / 1; height: var(--framer-aspect-ratio-supported, 256px); }", ".framer-xloUg.framer-v-1bq6ug6.framer-1wz4yup { aspect-ratio: 2.56 / 1; height: var(--framer-aspect-ratio-supported, 100px); }", ".framer-xloUg.framer-v-17q0j7m.framer-1wz4yup { aspect-ratio: 0.46545454545454545 / 1; height: var(--framer-aspect-ratio-supported, 550px); }", ".framer-xloUg.framer-v-vkpzqb.framer-1wz4yup { aspect-ratio: 0.5688888888888889 / 1; height: var(--framer-aspect-ratio-supported, 450px); }", ".framer-xloUg.framer-v-15blxg0.framer-1wz4yup { height: 320px; width: 352px; }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 256
 * @framerIntrinsicWidth 256
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["fixed","fixed"]},"zTBLJni3L":{"layout":["fixed","fixed"]},"gkK1mOqKf":{"layout":["fixed","fixed"]},"ZW1TDqz9I":{"layout":["fixed","fixed"]},"fSMUMbj8g":{"layout":["fixed","fixed"]},"b0F184wE7":{"layout":["fixed","fixed"]},"Ac_FnKne_":{"layout":["fixed","fixed"]},"z8sZWDg8O":{"layout":["fixed","fixed"]},"y5dF9DJox":{"layout":["fixed","fixed"]},"rBiO05SxQ":{"layout":["fixed","fixed"]}}}
 * @framerVariables {"WKR34BYYJ":"image","vhrLqezAm":"mouseEnter"}
 * @framerImmutableVariables true
 * @framerDisplayContentsDiv false
 */
const FrameriRxl8vfIn: React.ComponentType<Props> = withCSS(Component, css, "framer-xloUg") as typeof Component;
export default FrameriRxl8vfIn;

FrameriRxl8vfIn.displayName = "Image / Aspect Ratio Copy 6";

FrameriRxl8vfIn.defaultProps = {height: 256, width: 256};

addPropertyControls(FrameriRxl8vfIn, {variant: {options: ["khaD8joO3", "zTBLJni3L", "gkK1mOqKf", "ZW1TDqz9I", "fSMUMbj8g", "b0F184wE7", "Ac_FnKne_", "z8sZWDg8O", "y5dF9DJox", "rBiO05SxQ"], optionTitles: ["Default", "Image 4:3", "Image 3:2", "Image 16:9", "Image 4:5", "Image 1:1", "Variant 7", "Variant 8", "Variant 9", "Variant 10"], title: "Variant", type: ControlType.Enum}, WKR34BYYJ: {title: "Image", type: ControlType.ResponsiveImage}, vhrLqezAm: {title: "Mouse Enter", type: ControlType.EventHandler}} as any)

addFonts(FrameriRxl8vfIn, [])